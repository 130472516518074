// Revolution
var revControl = $('.js-rev');
revControl.each(function () {
    var that = $(this);
    var option = {
        layout: 'auto',
        arrows: true,
        stylearrow: 'uranus',
        hideonleavearrow: false,
        bullets: $(".rev-item", that).length > 1 ? true : false,
        stylebullet: 'hermes',
        hideonleavebullet: false,
        hoffbullet: 0,
        voffbullet: 20,
        spacebullet: 5,
        lhoffarrow: 0,
        lvoffarrow: 0,
        rhoffarrow: 0,
        rvoffarrow: 0,
        delay: 6000,
        height: 400,
        fullscreenalignforce: 'off',
        parallaxon: false,
        carouselon: false,
        variable: false

    };

    for (var k in option) {
        if (option.hasOwnProperty(k)) {
            if (that.attr('data-rev-' + k) != null) {
                option[k] = that.data('rev-' + k);
            }
        }
    }

    var tmp = '<span class="tp-bullet-number">{{param1}}</span>';
    if (option.variable === false) {
        tmp = '<span class="tp-bullet-title"></span>';
    }

    that.show().revolution({
        // stopLoop: "on",
        // stopAfterLoops: 0,
        // stopAtSlide: 1,
        sliderLayout: option.layout,
        responsiveLevels: [1201, 1200, 992, 768, 576],
        gridwidth:[1170, 930, 690, 510, 576],
        gridheight: [option.height, option.height, option.height, option.height],
        visibilityLevels:[1201, 1200, 992, 768, 576],
        delay: option.delay,
        // fullScreenOffsetContainer: "#header, .rev-spacer",
        disableProgressBar: "on",
        fullScreenAlignForce: option.fullscreenalignforce,
        navigation: {
        //     onHoverStop: "off",
        //     arrows: {
        //         enable: option.arrows,
        //         style: option.stylearrow,
        //         hide_onleave: option.hideonleavearrow,
        //         left: {
        //             h_offset: option.lhoffarrow,
        //             v_offset: option.lvoffarrow
        //         },
        //         right: {
        //             h_offset: option.rhoffarrow,
        //             v_offset: option.rvoffarrow
        //         }
        //     },

            bullets: {
                enable: option.bullets,
                style: option.stylebullet,
                hide_onleave: option.hideonleavebullet,
                hide_onmobile: true,
                h_align: 'center',
                v_align: 'bottom',
                h_offset: option.hoffbullet,
                v_offset: option.voffbullet,
                space: option.spacebullet,
                tmp: tmp
            }
        }
    });
})

var logoSwiper = new Swiper('.main-logo-swiper', {
    slidesPerView: 3,
    spaceBetween: 100,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        1024: {
          slidesPerView: 3,
          spaceBetween: 100,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        400: {
          slidesPerView: 1,
          spaceBetween: 10,
        }
      }
});